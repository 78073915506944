import AwesomeSlider from 'react-awesome-slider';
import React from 'react';
import sliderImage0 from "./images/1.png";
import Navigation from '../navigation/navigation';
import 'react-awesome-slider/dist/styles.css';
import "./slider.scss";
import logo from "./images/logo.png";

export default function Slider() {
    return (
        <AwesomeSlider
        bullets={false}
        organicArrows={false}
        buttons={false}
        infinite={false}
        mobileTouch={false}
        >
            <div data-src={sliderImage0}>
                <Navigation showLogo={false}/> 
                <div className="slider-center-content">
                    <img src={logo}/>
                    {/* <h1> Beat online travel pricing </h1>
                    <p> We offer airfares, hotels, and vacation packages at a discount to all online retailers. </p> */}
                </div>
            </div> 
        </AwesomeSlider>

    )
}

