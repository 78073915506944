import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import "./callout.scss";

export default function Callout(){
    return(
        <div className="callout">
            <div className="callout__content">
                <p> 
                    <FontAwesomeIcon icon={faPhone} className="callout__content__icon"/> 
                    <a href="tel:8008802335"> 800-880-2335 </a>
                </p>
                <p> 
                    <FontAwesomeIcon icon={faEnvelope} className="callout__content__icon"/> 
                    <a href="mailto:viharatravels3@gmail.com"> viharatravels3@gmail.com </a>
                </p> 

            </div>
        </div>
    )
}
