import React from "react"
import Callout from "../../components/callout/callout";
import Slider from "../../components/slider/slider";
import SEOHead from "../../components/seo/GlobalHead";
import "./index.scss";

export default function Home() {
  return (
    <>
    <SEOHead/>
    <div className="indexWrapper">
      <Callout/>
      <Slider/>
    </div>
    </>
  )
}
